<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-table
          style="margin-top: 10px;"
          :dataSource="list"
          :pagination="pagination"
          :columns="columns"
          bordered
          rowKey="id"
          size="middle"
      >
        <template #bodyCell="{ column }">
          <template v-if="column.key == 'action'">
            <a-dropdown>
              <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
              <template #overlay>
                <a-menu>
                  <div @click="toSee()">
                    <a-menu-item>
                      <a>查看</a>
                    </a-menu-item>
                  </div>
                  <div @click="toEdit()">
                    <a-menu-item>
                      <a>编辑</a>
                    </a-menu-item>
                  </div>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
  <temp v-if="showModal" :isSee="isSee" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue';
import { Icon } from "@/components/icon/icon.js";
export default {
  components: { Icon, temp },
  data() {
    return {
      loading: false,
      showModal: false,
      isSee: false,
      list: [
        { key: 1, title: '企业文化' },
        { key: 2, title: '企业文化' },
        { key: 3, title: '企业文化' },
        { key: 4, title: '企业文化' },
        { key: 5, title: '企业文化' },
      ],
      columns: [{
        title: '标题',
        dataIndex: 'title',
      }, {
        title: '操作',
        key: 'action',
        width: 300
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
  },
  methods: {
    onBack() {
      this.showModal = false;
    },
    toEdit() {
      this.isSee = false;
      this.showModal = true;
    },
    toSee() {
      this.isSee = true;
      this.showModal = true;
    }
  }
}
</script>

<style>
</style>
